import React, { useState } from 'react';
import './index.css'; // Import styles specific to this component
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';

import './index.css'; // Make sure this path is correct

const Product = ({ isAdmin, product, firebase }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [order, setOrder] = useState(product.order || 0); // Initialize with product's current order
    const [products, setProducts] = useState([]);

    // Function to handle order change
    const handleOrderChange = (e) => {
        setOrder(e.target.value);
    };

    // Function to save the new order to Firestore
    const saveOrder = () => {
        // Query for the product by name
        firebase.db.collection('products').where('name', '==', product.name).get()
            .then(querySnapshot => {
                // Check if the product exists
                if (!querySnapshot.empty) {
                    // Get the first document matching the product name
                    const productDoc = querySnapshot.docs[0];

                    // Update the order field of the found document
                    productDoc.ref.update({ order: Number(order) })
                        .then(() => {
                            setIsEditing(false);
                            alert('Order updated successfully!');
                        })
                        .catch(error => {
                            alert('Error updating order: ' + error.message);
                        });
                } else {
                    alert('No product found with the given name.');
                }
            })
            .catch(error => {
                alert('Error finding product: ' + error.message);
            });
    };

    const handleDeleteProduct = async (name) => {
        if (window.confirm('Are you sure you want to delete this product?')) {
          try {
            const query = firebase.db.collection('products').where("name", "==", name);
            const querySnapshot = await query.get();
            const batch = firebase.db.batch();
      
            querySnapshot.forEach((doc) => {
              batch.delete(doc.ref);
            });
      
            await batch.commit();
            setProducts(products.filter(product => product.name !== name));
            alert('Product deleted successfully!');
          } catch (error) {
            alert('Error removing product: ' + error.message);
          }
        }
      };
      

    // Ensure there is at least one image URL
    const imageUrl = product.imageUrls && product.imageUrls[0] ? product.imageUrls[0] : 'default-image-url.jpg';

    return (
        <div>
            <Link to={`/product/${product.name}`} style={{ color: 'inherit', textDecoration: 'none' }}>
            <div className="product-container">
                <div className="product-box">
                    <img src={imageUrl} alt={product.name} />
                </div>
                <div className="product-name">{product.name}</div>
            </div>
            </Link>
            {/* Show order input field only for admin users */}
            {isAdmin && (
                <div>
                    {isEditing ? (
                        <div>
                            <input
                                type="number"
                                value={order}
                                onChange={handleOrderChange}
                            />
                            <button onClick={saveOrder}>Save</button>
                            <button onClick={() => setIsEditing(false)}>Cancel</button>
                        </div>
                    ) : (
                        <button onClick={() => setIsEditing(true)}>Edit Order</button>
                    )}
                    {isAdmin && (
                        <button onClick={() => handleDeleteProduct(product.name)}>
                        Delete
                        </button>
                    )}
                </div>
            )}
            {/* You can add more product details or buttons/actions here */}
        </div>
    );
};

export default withFirebase(Product); // Wrap Product with withFirebase HOC
