import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';
import Music from '../Music';
import MusicUploadForm from '../MusicUploadForm'; // Ensure this is the correct path to your MusicUploadForm
import { Container, Row, Col } from 'react-bootstrap';

import './index.css'; // Ensure this path is correct for your styles

const mainCategories = ['All', 'Taime', 'Collaboration'];
const subCategories = {
  Taime: ['Album', 'EP', 'Single'],
  Collaboration: ['Album', 'EP', 'Single'],
};
const Musics = ({ firebase }) => {
  const [musics, setMusics] = useState([]);
  const [activeMainCategory, setActiveMainCategory] = useState('All');
  const [activeSubCategory, setActiveSubCategory] = useState('All');
  const [userDetails, setUserDetails] = useState(null);
  const [aboutContent, setAboutContent] = useState({ spotifyUrl: '' });
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    // Subscription to the aboutPage content
    const unsubscribeAboutPage = firebase.db.collection('aboutPage').doc('content').onSnapshot((doc) => {
      if (doc.exists) {
        setAboutContent(doc.data());
      } else {
        console.log("No such document!");
      }
    });

    // Subscription to the auth state to get user details
    const unsubscribeAuth = firebase.auth.onAuthStateChanged(async user => {
      if (user) {
        const userRef = firebase.db.collection('users').doc(user.uid);
        const userDoc = await userRef.get();
        if (userDoc.exists) {
          setUserDetails(userDoc.data());
        }
      } else {
        setUserDetails(null);
      }
    });

    // Subscription to the musics collection
    const unsubscribeMusics = firebase.db.collection('musics').orderBy('order')
      .onSnapshot(snapshot => {
        const musicsList = [];
        snapshot.forEach(doc => musicsList.push({ id: doc.id, ...doc.data() }));
        setMusics(musicsList);
      }, error => console.log(error));

    // Unsubscribe from both when the component unmounts
    return () => {
      unsubscribeAboutPage();
      unsubscribeAuth();
      unsubscribeMusics(); // Add this to your unsubscribe logic
    };
  }, [firebase.db, firebase.auth]);

  const isAdmin = userDetails && userDetails.admin;

  const handleMainCategoryClick = (category) => {
    setActiveMainCategory(category);
    setActiveSubCategory('All'); // Reset subcategory when changing main category
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSubCategoryClick = (subcategory) => {
    setActiveSubCategory(subcategory);
  };

  // Filter musics by main category and subcategory
  const filteredMusics = musics.filter(music =>
    (activeMainCategory === 'All' || music.category === activeMainCategory) &&
    (activeSubCategory === 'All' || music.subCategory === activeSubCategory));

  return (
    <Container fluid className="musics-container">
      <iframe
        title="Spotify Embed"
        className="spotify-iframe"
        src={aboutContent.spotifyUrl}
        allowFullScreen
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy">
      </iframe>

      <div className="platforms-link-container">
        <a href={aboutContent.distrokidUrl} className="platforms-link">See more platforms</a>
      </div>

      <hr className="dashed-line" />

      <div className="filter-container">
        {/* Row for the 'Hide Filters' toggle */}
        <div className="toggle-filters-row">
          <div onClick={toggleFilters} className="show-filters">
            {showFilters ? 'Hide Filters' : 'Show Filters'}
          </div>
        </div>

        {showFilters && (
          <>
            {/* Row for the main category filter bar */}
            <div className="filter-bar main-category-filter-bar">
              {mainCategories.map(category => (
                <div
                  onClick={() => handleMainCategoryClick(category)}
                  className={`filter-text ${activeMainCategory === category ? 'active' : ''}`}
                  key={category}
                >
                  {category}
                </div>
              ))}
            </div>

            {/* Row for the subcategory filter bar */}
            {activeMainCategory !== 'All' && subCategories[activeMainCategory] && (
              <div className="filter-bar subcategory-filter-bar">
                {subCategories[activeMainCategory].map(subCategory => (
                  <div
                    onClick={() => handleSubCategoryClick(subCategory)}
                    className={`filter-text ${activeSubCategory === subCategory ? 'active' : ''}`}
                    key={subCategory}
                  >
                    {subCategory}
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>

      {/* List filtered musics */}
      <Row>
        {filteredMusics.map(music => (
          // This will ensure that each music item takes up the full width of its row on all screen sizes
          <Col xs={12} key={music.id || music.name} className="music-grid-item">
            <Music music={music} isAdmin={isAdmin} />
          </Col>
        ))}
      </Row>

      {isAdmin && <MusicUploadForm firebase={firebase} />}
    </Container>
  );
};

export default withFirebase(Musics);
