
import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';
import AboutUploadForm from '../AboutUploadForm'; // Adjust the path as necessary
import { InstagramEmbed } from 'react-social-media-embed';
import { Container, Row, Col } from 'react-bootstrap';
import './index.css'

const About = ({ firebase }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [aboutContent, setAboutContent] = useState({
    title: '',
    description: '',
    instagramUrl: '',
    spotifyUrl: '',
    imageUrls: [], // Changed to an array to hold multiple images
  });

  useEffect(() => {
    // Subscription to the aboutPage content
    const unsubscribeAboutPage = firebase.db.collection('aboutPage').doc('content').onSnapshot((doc) => {
      if (doc.exists) {
        setAboutContent(doc.data());
      } else {
        console.log("No such document!");
      }
    });

    // Subscription to the auth state to get user details
    const unsubscribeAuth = firebase.auth.onAuthStateChanged(async user => {
      if (user) {
        const userRef = firebase.db.collection('users').doc(user.uid);
        const userDoc = await userRef.get();
        if (userDoc.exists) {
          setUserDetails(userDoc.data());
        }
      } else {
        setUserDetails(null);
      }
    });

    // Unsubscribe from both when the component unmounts
    return () => {
      unsubscribeAboutPage();
      unsubscribeAuth();
    };
  }, [firebase.db, firebase.auth]);

  const isAdmin = userDetails && userDetails.admin;

  function formatDescription(description) {
    return description.split('\\n').map((text, index) => (
      <React.Fragment key={index}>
        {text}
        <br />
      </React.Fragment>
    ));
  }

// Split images into two groups for left and right columns
const leftImages = aboutContent.imageUrls.filter((_, index) => index % 2 === 0);
const rightImages = aboutContent.imageUrls.filter((_, index) => index % 2 !== 0);

return (
  <Container className="about-container">
    <Row>
    <Col sm={4}>
      {leftImages.map((url, index) => (
        <img key={`left-${index}`} src={url} alt={`Left ${index}`} className="img-fluid" />
      ))}
    </Col>
    <Col sm={4} className="text-center">
      <div className="text-container">
        <h1 className="about-title">{aboutContent.title}</h1>
        {formatDescription(aboutContent.description)}
      </div>
    </Col>
    <Col sm={4}>
      {rightImages.map((url, index) => (
        <img key={`right-${index}`} src={url} alt={`Right ${index}`} className="img-fluid" />
      ))}
    </Col>
  </Row>

  {aboutContent.instagramUrl && (
        <Row className="justify-content-center">
          <Col md={2} lg={4}>
          <h1 className="about-title">Instagram</h1>
            <InstagramEmbed url={aboutContent.instagramUrl} />
          </Col>
        </Row>
      )}
      
      {isAdmin && (
        <Row>
          <Col>
            <AboutUploadForm firebase={firebase} />
          </Col>
        </Row>
      )}
    </Container>
);
};

export default withFirebase(About); // Wrap About with the HOC
