// Event.js
import React, { useState } from 'react';
import './index.css'; // Import styles specific to this component
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase'; // Import the Firebase higher-order component
import "./index.css"

const Event = ({ isAdmin, event, firebase }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [order, setOrder] = useState(event.order || 0); // Initialize with event's current order

    // Function to handle order change
    const handleOrderChange = (e) => {
        setOrder(e.target.value);
    };

    // Function to save the new order to Firestore
    const saveOrder = () => {
        // Query for the event by name
        firebase.db.collection('events').where('name', '==', event.name).get()
            .then(querySnapshot => {
                // Check if the event exists
                if (!querySnapshot.empty) {
                    // Get the first document matching the event name
                    const eventDoc = querySnapshot.docs[0];

                    // Update the order field of the found document
                    eventDoc.ref.update({ order: Number(order) })
                        .then(() => {
                            setIsEditing(false);
                            alert('Order updated successfully!');
                        })
                        .catch(error => {
                            alert('Error updating order: ' + error.message);
                        });
                } else {
                    alert('No event found with the given name.');
                }
            })
            .catch(error => {
                alert('Error finding event: ' + error.message);
            });
    };

    const handleDeleteEvent = async (name) => {
        if (window.confirm('Are you sure you want to delete this event?')) {
          try {
            const query = firebase.db.collection('events').where("name", "==", name);
            const querySnapshot = await query.get();
            const batch = firebase.db.batch();
      
            querySnapshot.forEach((doc) => {
              batch.delete(doc.ref);
            });
      
            await batch.commit();
            alert('Event deleted successfully!');
          } catch (error) {
            alert('Error removing event: ' + error.message);
          }
        }
    };

    // Ensure there is at least one event image URL
    const imageUrl = event.imageUrls && event.imageUrls[0] ? event.imageUrls[0] : 'default-image-url.jpg';

    return (
        <div className="event">
            <Link to={`/event/${event.name}`} className="event-link">
                <div className="event-image-container">
                    <img src={imageUrl} alt={event.name} className="event-image" />
                </div>
            </Link>
            <Link to={`/event/${event.name}`} className="event-name">
                {event.name}
            </Link>
            {/* Admin controls */}
            {isAdmin && (
                <div className="admin-controls">
                    {isEditing ? (
                        <div>
                            <input type="number" value={order} onChange={handleOrderChange} />
                            <button className="admin-button" onClick={saveOrder}>Save</button>
                            <button className="admin-button" onClick={() => setIsEditing(false)}>Cancel</button>
                        </div>
                    ) : (
                        <button className="admin-button" onClick={() => setIsEditing(true)}>Edit Order</button>
                    )}
                    <button className="admin-button" onClick={() => handleDeleteEvent(event.name)}>
                        Delete
                    </button>
                </div>
            )}
        </div>
    );
};

export default withFirebase(Event);
