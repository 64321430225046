import React, { useState, useContext, useEffect } from 'react';
import { Container, Nav, Navbar, Spinner } from 'react-bootstrap'; // Import from react-bootstrap directly
import { NavLink } from 'react-router-dom'; // Import NavLink from react-router-dom
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { withFirebase } from '../Firebase';
import { FirebaseContext } from '../Firebase';
import logoImage from '../../images/taime.png'; // Adjust the path to where your image is located
import logoImagePurple from '../../images/taime-purple.png'; // Your purple logo

import './index.css'

const Logo = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="logo-container">
      <Link to="/" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <img
          src={isHovered ? logoImagePurple : logoImage}
          className="navbar-logo"
          alt="TAIME logo"
        />
      </Link>
    </div>
  );
};

const NavigationAuth = () => (
    <>
      <Logo />
      <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={NavLink} to="/products" activeClassName="active">Fashion</Nav.Link>
              <Nav.Link as={NavLink} to="/musics" activeClassName="active">Music</Nav.Link>
              <Nav.Link as={NavLink} to="/events" activeClassName="active">Events</Nav.Link>
              <Nav.Link as={NavLink} to="/studio" activeClassName="active">Studio</Nav.Link>
              <Nav.Link as={NavLink} to="/about" activeClassName="active">About</Nav.Link>
              <Nav.Link as={NavLink} to="/contact" activeClassName="active">Contact</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link as={NavLink} to="/account" activeClassName="active">Account</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
  
const NavigationNonAuth = () => (
    <>
      <Logo />
      <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={NavLink} to="/products" activeClassName="active">Fashion</Nav.Link>
              <Nav.Link as={NavLink} to="/musics" activeClassName="active">Music</Nav.Link>
              <Nav.Link as={NavLink} to="/events" activeClassName="active">Events</Nav.Link>
              <Nav.Link as={NavLink} to="/studio" activeClassName="active">Studio</Nav.Link>
              <Nav.Link as={NavLink} to="/about" activeClassName="active">About</Nav.Link>
              <Nav.Link as={NavLink} to="/contact" activeClassName="active">Contact</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link as={NavLink} to="/login" activeClassName="active">Login</Nav.Link>
              <Nav.Link as={NavLink} to="/signup" activeClassName="active">Signup</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
);

const Navigation = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [userDetails, setUserDetails] = useState(null); // State to store the combined user details
    const firebase = useContext(FirebaseContext);

    useEffect(() => {
        // Listen for changes in the authentication state
        const listener = firebase.auth.onAuthStateChanged(async user => {
            if (user) {
                // Fetch user details from Firestore
                const userRef = firebase.db.collection('users').doc(user.uid);
                const userDoc = await userRef.get();
                if (userDoc.exists) {
                    // Combine the auth user object with the Firestore document data
                    const combinedUserDetails = {
                        ...user,
                        ...userDoc.data()
                    };
                    setUserDetails(combinedUserDetails);
                }
            } else {
                // If the user is not logged in, set userDetails to null
                setUserDetails(null);
            }
            setIsLoading(false);
        });

        // Cleanup subscription on unmount
        return () => listener();
    }, [firebase]);

    if (isLoading) {
        // Show a spinner or any other loading indicator
        return (
            <div className="text-center">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    // Existing auth and non-auth navigation
    return userDetails ? <NavigationAuth /> : <NavigationNonAuth />;
};


const withNavigation = Component => props => (
    <>
        <Navigation />
        <Component {...props} />
    </>
)

export { withNavigation }
export default withFirebase(Navigation);