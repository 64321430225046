import React from 'react'
import { createRoot } from 'react-dom/client';
import './index.css'
import App from './components/App'
import Firebase, { FirebaseContext } from './components/Firebase'
import * as serviceWorker from './serviceWorker'
import 'bootstrap/dist/css/bootstrap.min.css'
import Credits from './components/Credits'

const root = createRoot(document.getElementById('root'));
root.render(
    <FirebaseContext.Provider value={new Firebase()}>
    <div className="flex-container">
      <div className="flex-content">
        <App />
      </div>
      <div className="credits">
        <Credits />
      </div>
    </div>
  </FirebaseContext.Provider>,
);

serviceWorker.unregister()
