import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';
import ProductUploadForm from '../ProductUploadForm';
import Product from '../Product';
import { Container, Row, Col } from 'react-bootstrap';

import './index.css'; // Make sure this path is correct

const Products = ({ firebase }) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState('all');
  const [userDetails, setUserDetails] = useState(null);
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      const fetchedProducts = await firebase.getProducts();
      setProducts(fetchedProducts);

      // Extract unique categories from fetched products
      const uniqueCategories = ['all', ...new Set(fetchedProducts.map(p => p.category))];
      setCategories(uniqueCategories);
    };

    const unsubscribe = firebase.auth.onAuthStateChanged(async user => {
      if (user) {
        const userRef = firebase.db.collection('users').doc(user.uid);
        const userDoc = await userRef.get();
        if (userDoc.exists) {
          setUserDetails(userDoc.data());
        }
      } else {
        setUserDetails(null);
      }
    });

    fetchProducts();
    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [firebase]);

  const isAdmin = userDetails && userDetails.admin;

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  // Group products by category
  const groupedProducts = activeCategory === 'all' ? { all: products } : products.reduce((acc, product) => {
    if (product.category === activeCategory) {
      if (!acc[product.category]) {
        acc[product.category] = [];
      }
      acc[product.category].push(product);
    }
    return acc;
  }, {});

  return (
    <Container>
      <div className="filter-container">
        <div onClick={toggleFilters} className="show-filters">
          {showFilters ? 'Hide Filters' : 'Show Filters'}
        </div>
        {showFilters && (
          <div className="filter-bar">
            {categories.map(category => (
              <div
                onClick={() => setActiveCategory(category)}
                className={`filter-text ${activeCategory === category ? 'active' : ''}`}
                key={category}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </div>
            ))}
          </div>
        )}
      </div>
      {Object.entries(groupedProducts).map(([category, products]) => (
        <Row key={category} className="my-3"> {/* Add some vertical margin to each category */}
          <Col xs={12}>
            <h2 className="text-center">{category.charAt(0).toUpperCase() + category.slice(1)}</h2>
          </Col>
          {products.map(product => (
            // Ensure this is col-md-3 for 4 items per row on medium devices and larger
            <Col xs={6} sm={4} md={3} className="product-grid-item" key={product.id || product.name}>
              <Product product={product} isAdmin={isAdmin} />
            </Col>
          ))}
        </Row>
      ))}
    {isAdmin && <ProductUploadForm />}
  </Container>
  );
};

export default withFirebase(Products);
