import React from 'react';
import { withFirebase } from '../Firebase';
import { useNavigate } from 'react-router-dom';
import "./index.css"

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: null, // Add error state
        };
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    signInWithEmailAndPassword = async () => {
        const { email, password } = this.state;
        try {
            await this.props.firebase.signInWithEmailAndPassword(email, password);
            this.props.onLoginPageSuccess();
        } catch (error) {
            this.setState({ error: "Login failed: Please check your email and password." });
            console.error("Log In Error:", error);
        }
    };


    signInWithGoogle = async () => {
        try {
            await this.props.firebase.signInWithGoogle();
            this.props.onLoginPageSuccess();
        } catch (error) {
            this.setState({ error: "Google login failed: Please try again." });
            console.error("Google Log In Error:", error);
        }
    };

    render() {
        const { email, password, error } = this.state;

        return (
            <div className="center-container"> {/* Flex container for centering the form */}
                <div className="centered-form"> {/* Styles specific to the form */}
                    <h1>Login</h1>
                    {error && <div className="error-message">{error}</div>} {/* Display error message */}
                    <input
                        className="form-control" // Reuse the form-control class for consistent styling
                        name="email"
                        type="email"
                        value={email}
                        onChange={this.onChange}
                        placeholder="Email"
                    />
                    <input
                        className="form-control" // Reuse the form-control class for consistent styling
                        name="password"
                        type="password"
                        value={password}
                        onChange={this.onChange}
                        placeholder="Password"
                    />
                    <button 
                        className="submit-button" // Reuse the submit-button class for styling
                        onClick={this.signInWithEmailAndPassword}
                    >
                        Log In with Email
                    </button>
                    <button 
                        className="submit-button" // Reuse the submit-button class for styling
                        onClick={this.signInWithGoogle}
                    >
                        Login in with Google
                    </button>
                </div>
            </div>
        );
    }
}

function LoginPageWithNavigation(props) {
    const navigate = useNavigate();

    const handleLoginPageSuccess = () => {
        navigate('/'); // Redirect to home page
    };

    return <LoginPage {...props} onLoginPageSuccess={handleLoginPageSuccess} />;
}


export default withFirebase(LoginPageWithNavigation);
