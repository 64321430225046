import React, { useState, useEffect, useMemo } from 'react';
import Firebase from '../Firebase'; // Replace './Firebase' with the actual path to your Firebase class


function ProductUploadForm({ product }) {
  // Create an instance of Firebase
  const firebase = useMemo(() => new Firebase(), []);

  // State hooks for product details
  const [productName, setProductName] = useState('');
  const [productCategory, setProductCategory] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productFiles, setProductFiles] = useState([]);
  const [productProgress, setProductProgress] = useState(0);
  const [productOrder, setProductOrder] = useState(0); // Initialize with default order
  const [songUrl, setSongUrl] = useState(''); // State hook for song URL
  const [videoUrl, setVideoUrl] = useState(''); // State hook for song URL



  // Fill the form with existing product data if present
  useEffect(() => {
    if (productCategory) {
      // Fetch the highest order number from the same category
      firebase.db.collection('products')
        .where('category', '==', productCategory)
        .orderBy('order', 'desc')
        .limit(1)
        .get()
        .then(querySnapshot => {
          if (!querySnapshot.empty) {
            const highestOrderProduct = querySnapshot.docs[0];
            setProductOrder(highestOrderProduct.data().order + 1);
          } else {
            setProductOrder(0);
          }
        })
        .catch(error => {
          console.error("Error fetching highest order: ", error);
        });
    }
  }, [productCategory, firebase]);


  // Function to handle the product image file selection
  const handleProductImageChange = (e) => {
    setProductFiles(Array.from(e.target.files));
  };

  // Function to handle the change of product order
  const handleProductOrderChange = (e) => {
    setProductOrder(Number(e.target.value));
  };

  const handleUploadProduct = () => {
    const promises = [];
    productFiles.forEach((file) => {
      const uploadTask = firebase.storage.ref(`product_images/${file.name}`).put(file);
      promises.push(new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProductProgress(progress);
          },
          (error) => {
            reject(error);
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((url) => {
              resolve(url);
            });
          }
        );
      }));
    });
  
    Promise.all(promises).then((imageUrls) => {
      firebase.db.collection('products').add({
        name: productName,
        category: productCategory,
        description: productDescription,
        order: productOrder, // Add the order here
        imageUrls: imageUrls,
        songUrl: songUrl,
        videoUrl: videoUrl
      }).then(() => {
        setProductName('');
        setProductCategory('');
        setProductDescription('');
        setProductFiles([]);
        setProductOrder(0)
        setProductProgress(0);
        setSongUrl('');
        setVideoUrl('');
        alert('Product uploaded successfully!');
      }).catch((error) => {
        alert('Error uploading product: ' + error.message);
      });
    }).catch((error) => {
      alert('Error uploading images: ' + error.message);
    });
  };
  
  
  
  // Render the product upload form
  return (
    <div className="center-container">
      <div className="centered-form">
        <h2>Product Upload</h2>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
  
          <div>
            <h3>Name</h3>
            <input
              className="form-control"
              type="text"
              placeholder="Enter product name..."
              onChange={(e) => setProductName(e.target.value)}
              value={productName}
            />
          </div>
  
          <div>
            <h3>Category</h3>
            <select
              className="form-control"
              value={productCategory}
              onChange={(e) => setProductCategory(e.target.value)}
            >
              <option value="">Select category...</option>
              <option value="top">Top</option>
              <option value="bottom">Bottom</option>
              <option value="shoes">Shoes</option>
              <option value="bag">Bag</option>
              <option value="accessories">Accessories</option>
            </select>
          </div>
          
          <div>
            <h3>Description</h3>
            <textarea
              className="form-control"
              placeholder="Enter product description..."
              onChange={(e) => setProductDescription(e.target.value)}
              value={productDescription}
            />
          </div>
  
          <div>
            <h3>Song URL</h3>
            <input
              className="form-control"
              type="text"
              placeholder="Enter song URL..."
              onChange={(e) => setSongUrl(e.target.value)}
              value={songUrl}
            />
          </div>
  
          <div>
            <h3>Video URL</h3>
            <input
              className="form-control"
              type="text"
              placeholder="Enter video URL..."
              onChange={(e) => setVideoUrl(e.target.value)}
              value={videoUrl}
            />
          </div>
          
          <div>
            <h3>Images</h3>
            <input
              className="form-control"
              type="file"
              multiple
              onChange={handleProductImageChange}
            />
          </div>
  
          <div>
            <h3>Order</h3>
            <input
              className="form-control"
              type="number"
              placeholder="Enter product order..."
              onChange={handleProductOrderChange}
              value={productOrder}
            />
          </div>
          
          <button className="submit-button" onClick={handleUploadProduct}>Upload Product</button>
          
          {productProgress > 0 && (
            <div style={{ width: '100%', marginTop: '10px' }}>
              <progress value={productProgress} max="100" style={{ width: '100%' }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
  
}

export default ProductUploadForm;
