import React from 'react';
import { withFirebase } from '../Firebase';
import { useNavigate } from 'react-router-dom';

import './index.css'; // Import shared CSS file


class SignupPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            error: null, // Add error state
        };
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    signUpWithEmailAndPassword = async () => {
        const { email, password, firstName, lastName } = this.state;
        try {
            const userCredential = await this.props.firebase.auth.createUserWithEmailAndPassword(email, password);
            const user = userCredential.user;
            // Set the displayName on the user
            await user.updateProfile({
                displayName: `${firstName} ${lastName}`
            });
            // Save user data to Firestore
            await this.props.firebase.setUserData(user.uid, {
                email: user.email,
                firstName: firstName,
                lastName: lastName,
                createdAt: new Date()
            });
            // Handle successful sign up (e.g., redirect to dashboard)
            this.props.onSignUpSuccess();
        } catch (error) {
            this.setState({ error: "Sign Up failed:" + error });
            console.error("Sign Up Error:", error);
        }
    };


    render() {
        const { email, password, firstName, lastName, error } = this.state;

        return (
            <div className="center-container"> {/* Flex container for centering the form */}
            <div className="centered-form"> {/* Styles specific to the form */}
                <h1>Sign Up</h1>
                {error && <div className="error-message">{error}</div>} {/* Display error message */}
                    <input
                        className="form-control"
                        name="firstName"
                        type="text"
                        value={firstName}
                        onChange={this.onChange}
                        placeholder="First Name"
                    />
                    <input
                        className="form-control"
                        name="lastName"
                        type="text"
                        value={lastName}
                        onChange={this.onChange}
                        placeholder="Last Name"
                    />
                    <input
                        className="form-control"
                        name="email"
                        type="email"
                        value={email}
                        onChange={this.onChange}
                        placeholder="Email"
                    />
                    <input
                        className="form-control"
                        name="password"
                        type="password"
                        value={password}
                        onChange={this.onChange}
                        placeholder="Password"
                    />
                    <button 
                        className="submit-button" // Reuse the submit-button class for consistent styling
                        onClick={this.signUpWithEmailAndPassword}
                    >
                        Sign Up with Email
                    </button>
                    <button 
                        className="submit-button" // Reuse the submit-button class for consistent styling
                        onClick={this.props.firebase.signInWithGoogle}
                    >
                        Sign Up with Google
                    </button>
                </div>
            </div>
        );
    }
}

function SignupPageWithNavigation(props) {
    const navigate = useNavigate();

    const handleSignUpSuccess = () => {
        navigate('/'); // Redirect to home page
    };

    return <SignupPage {...props} onSignUpSuccess={handleSignUpSuccess} />;
}

export default withFirebase(SignupPageWithNavigation);
