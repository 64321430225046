import React from 'react';
import { withFirebase } from '../Firebase';
import { useNavigate } from 'react-router-dom';
import './index.css'

class AccountPageBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordOne: '',
            passwordTwo: '',
            error: null,
        };
    }

    onLogout = async () => {
        await this.props.firebase.doSignOut();
        this.props.navigate('/'); // Navigate using a prop
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleDeleteAccount = async () => {
        // Isolated confirmation step
        if (window.confirm("Are you sure you want to delete your account? This action cannot be undone.")) {
            try {
                await this.props.firebase.deleteUserAccount();
                this.props.navigate('/'); // Navigate using a prop
            } catch (error) {
                this.setState({ error });
            }
        }
    };

    onSubmit = event => {
        const { passwordOne } = this.state;

        this.props.firebase
            .doPasswordUpdate(passwordOne)
            .then(() => {
                this.setState({ passwordOne: '', passwordTwo: '', error: null });
                alert('Password updated successfully!');
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    componentDidMount() {
        this.listener = this.props.firebase.auth.onAuthStateChanged(
            authUser => {
                if (authUser) {
                    this.setState({ email: authUser.email });
                } else {
                    this.setState({ email: null });
                }
            },
            () => {
                this.setState({ email: null });
            }
        );
    }
    
    componentWillUnmount() {
        this.listener && this.listener(); // Unsubscribe from the listener when the component unmounts
    }
    

    render() {
        const { passwordOne, passwordTwo, email, error } = this.state;
        const isInvalid = passwordOne !== passwordTwo || passwordOne === '';

        return (
            <div className="center-container">
                <div className="centered-form">
                    <h1>Account Settings</h1>
                    <div>Email: {email}</div> {/* Display the user's email */}
                    <button 
                        className="submit-button logout-button"
                        onClick={this.onLogout}
                    >
                        Logout
                    </button>
                    <form onSubmit={this.onSubmit}>
                        <input
                            className="form-control"
                            name="passwordOne"
                            value={passwordOne}
                            onChange={this.onChange}
                            type="password"
                            placeholder="New Password"
                        />
                        <input
                            className="form-control"
                            name="passwordTwo"
                            value={passwordTwo}
                            onChange={this.onChange}
                            type="password"
                            placeholder="Confirm New Password"
                        />
                        <button 
                            className="submit-button"
                            disabled={isInvalid} 
                            type="submit"
                        >
                            Reset My Password
                        </button>

                        {error && <p>{error.message}</p>}
                    </form>
                    <button 
                        className="submit-button delete-account-button"
                        onClick={this.handleDeleteAccount}
                    >
                        Delete Account
                    </button>
                </div>
            </div>
        );
    }
}

// Wrap AccountPageBase with the navigation functionality
const AccountPage = (props) => {
    const navigate = useNavigate();

    // Pass the navigate function to the AccountPageBase component
    return <AccountPageBase {...props} navigate={navigate} />;
};

export default withFirebase(AccountPage);