import React, { useState, useEffect } from 'react';
import Firebase from '../Firebase'; // Import the default export

function AboutUploadForm() {
  const [firebase] = useState(new Firebase()); // Create an instance of Firebase
  const [uploadProgress, setUploadProgress] = useState(0); // New state for upload progress
  const [aboutContent, setAboutContent] = useState({
    title: '',
    description: '',
    instagramUrl: '',
    spotifyUrl: '',
    imageUrls: [] // Initialize as an empty array
  });

  // Function to load existing about content from Firestore when the component mounts
  useEffect(() => {
    const unsubscribe = firebase.db.collection('aboutPage').doc('content').onSnapshot((doc) => {
      if (doc.exists) {
        setAboutContent(doc.data());
      }
    });
  
    return () => unsubscribe(); // Cleanup the subscription
  }, [firebase]);

  // Function to update the about content in Firestore
  const handleAboutUpdate = () => {
    // Ensure that we have an array before trying to save it
    const updatedContent = {
      ...aboutContent,
      imageUrls: Array.isArray(aboutContent.imageUrls) ? aboutContent.imageUrls : [],
    };
  
    firebase.db.collection('aboutPage').doc('content').set(updatedContent, { merge: true })
      .then(() => {
        alert('About content updated successfully!');
      })
      .catch((error) => {
        alert('Error updating content: ' + error.message);
      });
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to Array
    if (files.length === 0) return;
  
    // Reset progress to 0 when new upload starts
    setUploadProgress(0);
  
    const storageRef = firebase.storage.ref();
    const uploadPromises = files.map((file) => {
      const fileRef = storageRef.child(`aboutPage/${file.name}`);
      const uploadTask = fileRef.put(file);
  
      // Listen for state changes to update progress
      uploadTask.on('state_changed', (snapshot) => {
        // Calculate and update progress
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      });
  
      return uploadTask.then(() => fileRef.getDownloadURL());
    });
  
    Promise.all(uploadPromises)
      .then((urls) => {
        // Replace the existing URLs with the new ones
        setAboutContent((prevContent) => ({
          ...prevContent,
          imageUrls: urls // Replace existing URLs
        }));
      })
      .catch((error) => {
        console.error('Error uploading files:', error);
      });
  };
  
  
  // Handle changes to the about content form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAboutContent(prevContent => ({
      ...prevContent,
      [name]: value
    }));
  };

  

  return (
    <div className="center-container">
      <div className="centered-form">
        <h2>Update About Content</h2>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
  
          <div>
            <h3>Title</h3>
            <input
              className="form-control"
              name="title"
              placeholder="Title"
              value={aboutContent.title}
              onChange={handleInputChange}
            />
          </div>
  
          <div>
            <h3>Description</h3>
            <textarea
              className="form-control"
              name="description"
              placeholder="Description"
              value={aboutContent.description}
              onChange={handleInputChange}
            />
          </div>
  
          <div>
            <h3>Instagram Url</h3>
            <input
              className="form-control"
              name="instagramUrl"
              type="text"
              placeholder="Instagram URL"
              value={aboutContent.instagramUrl}
              onChange={handleInputChange}
            />
          </div>
  
          <div>
            <h3>Spotify Url</h3>
            <input
              className="form-control"
              name="spotifyUrl"
              type="text"
              placeholder="Spotify URL"
              value={aboutContent.spotifyUrl}
              onChange={handleInputChange}
            />
          </div>
  
          <div>
            <h3>Image</h3>
            <input
              className="form-control"
              name="images"
              type="file"
              multiple // Allows multiple file selection
              onChange={handleImageUpload}
            />
          </div>
  
          <button className="submit-button" onClick={handleAboutUpdate}>Update About Content</button>
  
          {uploadProgress > 0 && (
            <div style={{ width: '100%', marginTop: '10px' }}>
              <h3>Upload Progress:</h3>
              <progress value={uploadProgress} max="100" style={{ width: '100%' }}></progress>
              <span>{uploadProgress.toFixed(2)}%</span>
            </div>
          )}
  
        </div>
      </div>
    </div>
  );
  
}

export default AboutUploadForm;
