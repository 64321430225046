import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/compat/database";
import "firebase/compat/storage";


const config = {
    apiKey: "AIzaSyBAwbFpJr5m1YMcxU3jt5Juv2hmnrqvXa0",
    authDomain: "taime-co.firebaseapp.com",
    projectId: "taime-co",
    storageBucket: "taime-co.appspot.com",
    messagingSenderId: "1036522627190",
    appId: "1:1036522627190:web:67a938e3d9523cc8ebbf57",
    measurementId: "G-D5KQXHW3CP"
};

class Firebase {
    constructor() {
        firebase.initializeApp(config);
        this.auth = firebase.auth();
        this.db = firebase.firestore();
        this.storage = firebase.storage();
        this.googleProvider = new firebase.auth.GoogleAuthProvider();
    }

    static storageInstance;

    // Method to fetch products from Firestore
    getProducts = async () => {
        try {
            console.log("Fetching products from Firestore...");
            
            const snapshot = await this.db.collection('products').orderBy('category').orderBy('order').get();
            if (!snapshot.empty) {
                console.log(`${snapshot.size} products fetched successfully.`);
                return snapshot.docs.map(doc => {
                    const data = doc.data();
                    console.log(`Product ID: ${data.id}, Name: ${data.name}, Image URL: ${data.imageUrl}`);
                    return data;
                });
            } else {
                console.log("No products found in Firestore.");
                return [];
            }
        } catch (error) {
            console.error("Error fetching products from Firestore:", error);
            return [];
        }
    }

    // Method to fetch musics from Firestore
    getMusics = async () => {
        try {
            console.log("Fetching musics from Firestore...");
            
            const snapshot = await this.db.collection('musics').orderBy('category').orderBy('subCategory').orderBy('order').get();
            if (!snapshot.empty) {
                console.log(`${snapshot.size} musics fetched successfully.`);
                return snapshot.docs.map(doc => {
                    const data = doc.data();
                    console.log(`Music ID: ${data.id}, Name: ${data.name}, Image URL: ${data.imageUrl}`);
                    return data;
                });
            } else {
                console.log("No music found in Firestore.");
                return [];
            }
        } catch (error) {
            console.error("Error fetching musics from Firestore:", error);
            return [];
        }
    }

    // Method to fetch events from Firestore
    getEvents = async () => {
        try {
            console.log("Fetching events from Firestore...");
            
            const snapshot = await this.db.collection('events').get();
            
            if (!snapshot.empty) {
                console.log(`${snapshot.size} events fetched successfully.`);
                return snapshot.docs.map(doc => {
                    const data = doc.data();
                    console.log(`Event ID: ${data.id}, Name: ${data.name}, Image URL: ${data.eventUrl}`);
                    return data;
                });
            } else {
                console.log("No events found in Firestore.");
                return [];
            }
        } catch (error) {
            console.error("Error fetching events from Firestore:", error);
            return [];
        }
    }

    async getProductByName(name) {
        // This will search all products to find the one with the matching name
        const querySnapshot = await this.db.collection("products").where("name", "==", name).get();
        if (!querySnapshot.empty) {
            // Return the data of the first matching product
            return querySnapshot.docs[0].data();
        } else {
            throw new Error("No such product!");
        }
    }

    async getMusicByName(name) {
        // This will search all music to find the one with the matching name
        const querySnapshot = await this.db.collection("musics").where("name", "==", name).get();
        if (!querySnapshot.empty) {
            // Return the data of the first matching product
            return querySnapshot.docs[0].data();
        } else {
            throw new Error("No such music!");
        }
    }

    async getEventByName(name) {
        // This will search all events to find the one with the matching name
        const querySnapshot = await this.db.collection("events").where("name", "==", name).get();
        if (!querySnapshot.empty) {
            // Return the data of the first matching event
            return querySnapshot.docs[0].data();
        } else {
            throw new Error("No such event!");
        }
    }

    // Auth API
    signInWithGoogle = async () => {
        try {
            const result = await this.auth.signInWithPopup(this.googleProvider);
            const user = result.user;
            // Check if user exists in Firestore
            const userRef = this.db.collection('users').doc(user.uid);
            const docSnap = await userRef.get();

            // If user doesn't exist in Firestore, create a new record
            if (!docSnap.exists) {
                await userRef.set({
                    uid: user.uid,
                    email: user.email,
                    name: user.displayName,
                    authProvider: "google",
                    photoURL: user.photoURL,
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    doPasswordUpdate = (password) => {
        return this.auth.currentUser.updatePassword(password);
    }    

    signUpWithEmailAndPassword = async (email, password) => {
        const userCredential = await this.auth.createUserWithEmailAndPassword(email, password);
        const user = userCredential.user;
        await this.setUserData(user.uid, { email: user.email, createdAt: new Date()});
        return user;
    }

    signInWithEmailAndPassword = (email, password) => {
        return this.auth.signInWithEmailAndPassword(email, password);
    }

    doSignOut = () => this.auth.signOut(); // Define the logOut method

    deleteUserAccount = async () => {
        const currentUser = this.auth.currentUser;
    
        if (currentUser) {
            try {
                await currentUser.delete();
                console.log("User account deleted successfully.");
                // Perform any additional cleanup tasks here like redirecting the user
            } catch (error) {
                if (error.code === 'auth/requires-recent-login') {
                    // The user's credential is too old. They need to sign in again.
                    console.error("Please sign in again to confirm your identity before deleting your account.");
                    // Implement re-authentication here if necessary
                } else {
                    console.error("Error deleting user account:", error);
                }
            }
        }
    }

    // User API
    getUserData = userId => this.db.collection('users').doc(userId).get()
    setUserData = (userId, data) => this.db.collection('users').doc(userId).set({ ...data }, { merge: true })

    static getStorage() {
        if (!Firebase.storageInstance) {
            const firebaseInstance = new Firebase();
            Firebase.storageInstance = firebaseInstance.storage;
        }
        return Firebase.storageInstance;
    }
}

export default Firebase;
