import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';

const LandingUploadForm = ({ firebase }) => {
  const [productNames, setProductNames] = useState({ 1: '', 2: '', 3: '', 4: '' });
  const [products, setProducts] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoTitle, setVideoTitle] = useState(''); // State for Landing Video Title
  const [spotifyUrl, setSpotifyUrl] = useState(''); // State variable for the Spotify URL
  const [distrokidUrl, setDistrokidUrl] = useState(''); // State for Distrokid URL
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await firebase.db.collection('products').get();
        const productsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setProducts(productsData);
      } catch (error) {
        setError(error);
        console.error("Error fetching products: ", error);
      }
    };

    const fetchLandingContent = async () => {
      try {
        const contentSnapshot = await firebase.db.collection('aboutPage').doc('content').get();
        if (contentSnapshot.exists) {
          const contentData = contentSnapshot.data();
          setSpotifyUrl(contentData.spotifyUrl || '');
          setDistrokidUrl(contentData.distrokidUrl || '');
        }
      } catch (error) {
        setError(error);
        console.error("Error fetching landing content: ", error);
      }
    };

    const fetchLandingVideo = async () => {
      try {
        const videoSnapshot = await firebase.db.collection('landing').doc('landing_video').get();
        if (videoSnapshot.exists) {
          const videoData = videoSnapshot.data();
          setVideoUrl(videoData.youtubeUrl || '');
          setVideoTitle(videoData.title || '');
        }
      } catch (error) {
        setError(error);
        console.error("Error fetching landing video: ", error);
      }
    };

    const fetchLandingProducts = async () => {
      try {
        const landingProductNames = {};
        for (let idx = 1; idx <= 4; idx++) {
          const productSnapshot = await firebase.db.collection('landing').doc(`product_${idx}`).get();
          if (productSnapshot.exists) {
            landingProductNames[idx] = productSnapshot.data().name || '';
          }
        }
        setProductNames(landingProductNames);
      } catch (error) {
        setError(error);
        console.error("Error fetching landing products: ", error);
      }
    };

    fetchProducts();
    fetchLandingContent();
    fetchLandingVideo();
    fetchLandingProducts();
  }, [firebase]);
  

  // Function to update the Spotify URL
  const handleSpotifySubmit = async (event) => {
    event.preventDefault();
    try {
      await firebase.db.collection('aboutPage').doc('content').set({
        spotifyUrl
      }, { merge: true });
      alert('Spotify URL updated successfully!');
    } catch (error) {
      setError(error);
      alert("Error saving Spotify URL: " + error.message);
    }
  };

  // Function to update the landing products
  const handleProductSubmit = async (event) => {
    event.preventDefault();
    try {
      for (const idx in productNames) {
        const name = productNames[idx];
        if (name) {
          await firebase.db.collection('landing').doc(`product_${idx}`).set({
            idx,
            name,
            type: 'product'
          });
        }
      }
      setProductNames({ 1: '', 2: '', 3: '', 4: '' });
      alert('Products updated successfully!');
    } catch (error) {
      setError(error);
      alert("Error updating landing products: " + error.message);
    }
  };

  // Function to update the video URL and title
  const handleVideoSubmit = async (event) => {
    event.preventDefault();
    try {
      await firebase.db.collection('landing').doc('landing_video').set({
        youtubeUrl: videoUrl,
        title: videoTitle, // Title field added
        type: 'video'
      });
      setVideoUrl('');
      setVideoTitle('');
      alert('Landing page video updated successfully!');
    } catch (error) {
      setError(error);
      alert("Error updating landing video: " + error.message);
    }
  };

  // Function to update the Distrokid URL
  const handleDistrokidSubmit = async (event) => {
    event.preventDefault();
    try {
      await firebase.db.collection('aboutPage').doc('content').set({
        distrokidUrl
      }, { merge: true });
      alert('Distrokid URL updated successfully!');
    } catch (error) {
      setError(error);
      alert("Error saving Distrokid URL: " + error.message);
    }
  };

  // JSX for the form elements and buttons
  return (
    <div className="center-container">
      <div className="centered-form">
      {error && <p className="error">{error.message}</p>}
  
      {/* Form for updating product details */}
      <form onSubmit={handleProductSubmit}>
        <h2>Update Landing Products</h2>
        {[1, 2, 3, 4].map(idx => (
          <div key={idx}>
            <label>Select Product {idx}: </label>
            <select
              name={`productName${idx}`}
              value={productNames[idx]}
              onChange={(e) => setProductNames({ ...productNames, [idx]: e.target.value })}
              required
            >
              <option value="">Select a product</option>
              {products.map(product => (
                <option key={product.id} value={product.name}>
                  {product.name}
                </option>
              ))}
            </select>
          </div>
        ))}
          <button className="submit-button" type="submit">Update Products</button>
      </form>
                
      <hr className="dashed-line" />

      {/* Form for updating the landing page video URL and title */}
      <form onSubmit={handleVideoSubmit}>
        <h3>Update Landing Page Video</h3>
        <div>
          <label htmlFor="videoTitle">Video Title:</label>
          <input
            type="text"
            id="videoTitle"
            className="form-control"
            value={videoTitle}
            onChange={(e) => setVideoTitle(e.target.value)}
            placeholder="Enter Video Title"
            required
          />
        </div>
        <div>
          <label htmlFor="videoUrl">Video URL:</label>
          <input
            type="text"
            id="videoUrl"
            value={videoUrl}
            className="form-control"
            onChange={(e) => setVideoUrl(e.target.value)}
            placeholder="Enter YouTube URL"
            required
          />
        </div>
        <button className="submit-button" type="submit">Update Video</button>
      </form>
      
      <hr className="dashed-line" />

      {/* Form for updating the Spotify URL */}
      <form onSubmit={handleSpotifySubmit}>
        <h3>Update Spotify URL</h3>
        <div>
          <label htmlFor="spotifyUrl">Spotify URL:</label>
          <input
            type="text"
            id="spotifyUrl"
            value={spotifyUrl}
            className="form-control"
            onChange={(e) => setSpotifyUrl(e.target.value)}
            placeholder="Enter Spotify URL"
            required
          />
        </div>
        <button className="submit-button" type="submit">Update Spotify URL</button>
      </form>
      
      <hr className="dashed-line" />
      
      {/* Form for updating the Distrokid URL */}
      <form onSubmit={handleDistrokidSubmit}>
        <h3>Update Distrokid URL</h3>
        <div>
          <label htmlFor="distrokidUrl">Distrokid URL:</label>
          <input
            type="text"
            id="distrokidUrl"
            className="form-control"
            value={distrokidUrl}
            onChange={(e) => setDistrokidUrl(e.target.value)}
            placeholder="Enter Distrokid URL"
            required
          />
        </div>
        <button className="submit-button" type="submit">Update Distrokid URL</button>
      </form>
    </div>
    </div>
  );
};

export default withFirebase(LandingUploadForm);
