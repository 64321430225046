import React, { useState, useEffect } from 'react';
import Product from '../Product';
import Event from '../Event';
import LandingUploadForm from '../LandingUploadForm';
import { Container, Row, Col } from 'react-bootstrap'; // Import Bootstrap components
import { withFirebase } from '../Firebase';
import YouTube from 'react-youtube';
import { Link } from 'react-router-dom';

import './index.css';


const Landing = ({ firebase }) => {
  const [landingProducts, setLandingProducts] = useState([]);
  const [landingVideoUrl, setLandingVideoUrl] = useState('');
  const [landingSpotifyUrl, setLandingSpotifyUrl] = useState(''); // Added state for Spotify URL
  const [userDetails, setUserDetails] = useState(null);
  const [latestEvent, setLatestEvent] = useState(null); // State to hold
  const [landingVideoTitle, setLandingVideoTitle] = useState('');
  const [distrokidUrl, setDistrokidUrl] = useState('');


  useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged(async user => {
      if (user) {
        const userRef = firebase.db.collection('users').doc(user.uid);
        const userDoc = await userRef.get();
        if (userDoc.exists) {
          setUserDetails(userDoc.data());
        }
      } else {
        setUserDetails(null);
      }
    });

    const fetchLandingPageContent = async () => {
      try {
        const contentSnapshot = await firebase.db.collection('aboutPage').doc('content').get();
        if (contentSnapshot.exists) {
          const data = contentSnapshot.data();
          setDistrokidUrl(data.distrokidUrl || '');
        }
      } catch (error) {
        console.error("Error fetching landing page content: ", error);
      }
    };

    const fetchLandingProducts = async () => {
      try {
        const snapshot = await firebase.db.collection('landing').orderBy('idx').limit(4).get();
        const productPromises = snapshot.docs.map(doc =>
          firebase.db.collection('products').where('name', '==', doc.data().name).get()
        );
        const productSnapshots = await Promise.all(productPromises);
        const products = productSnapshots.map(snap => snap.docs.map(doc => doc.data())).flat();
        setLandingProducts(products);
      } catch (error) {
        console.error("Error fetching landing products: ", error);
      }
    };

    const fetchLandingVideo = async () => {
      try {
        const videoSnapshot = await firebase.db.collection('landing').doc('landing_video').get();
        if (videoSnapshot.exists) {
          const videoData = videoSnapshot.data();
          setLandingVideoUrl(videoData.youtubeUrl); // Fetches the YouTube URL
          setLandingVideoTitle(videoData.title);     // Fetches the Video Title
        }
      } catch (error) {
        console.error("Error fetching landing video: ", error);
      }
    };

    const fetchLandingSpotify = async () => {
      const aboutPageSnapshot = await firebase.db.collection('aboutPage').doc('content').get();
      if (aboutPageSnapshot.exists) {
        setLandingSpotifyUrl(aboutPageSnapshot.data().spotifyUrl);
      }
    };

    const fetchLatestEvent = async () => {
      try {
        // Assuming 'events' are sorted by a 'date' field, descending so the first one is the latest
        const snapshot = await firebase.db.collection('events').orderBy('date', 'desc').limit(1).get();
        if (!snapshot.empty) {
          setLatestEvent(snapshot.docs[0].data()); // Set the latest event
        }
      } catch (error) {
        console.error("Error fetching latest event: ", error);
      }
    };

    fetchLandingProducts();
    fetchLandingVideo();
    fetchLandingSpotify(); // Call the function to fetch Spotify URL
    fetchLatestEvent();
    fetchLandingPageContent();

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [firebase]);

  // Function to extract YouTube video ID from URL
  const getYouTubeID = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  const youtubeID = landingVideoUrl ? getYouTubeID(landingVideoUrl) : null;

  const isAdmin = userDetails && userDetails.admin;

  return (
    <div>
      {/* Conditional rendering for welcome message */}
      {userDetails && userDetails.name && (
            <Row className="justify-content-center">
              <Col>
                <div className="welcome-message">
                  Welcome, {userDetails.name}!
                </div>
              </Col>
            </Row>
      )}
      
      <Container fluid>
        <Container> {/* Wrap title in a Container */}
          <Row className="justify-content-center"> {/* This class centers the column(s) horizontally */}
            <Col> {/* Adjust the size props as needed */}
              <div className="title text-center"> {/* Use text-center to center the text within the column */}
                TAIME?
              </div>
            </Col>
          </Row>
        </Container>

        
        <Container> {/* Wrap products in a Container */}
          <Row>
            {landingProducts.map((product, index) => (
              <Col xs={12} sm={6} md={4} lg={3} key={index}> {/* Define the grid columns */}
                <Product product={product} firebase={firebase} />
              </Col>
            ))}
          </Row>
        </Container>

        <Row className="justify-content-center">
          <Col className="text-center">
            <Link to="/products/" className="see-all-link">
              See all
            </Link>
          </Col>
        </Row>

        <hr className="dashed-line" />

        


        {landingVideoUrl && (
          <Container>
            <Row className="justify-content-center">
              <Col>
                {/* Use the youtubeID variable here to pass the ID to the YouTube component */}
                <YouTube videoId={youtubeID} opts={{
                  width: '100%',
                  height: '600',
                }} />
              </Col>
            </Row>
          </Container>
        )}

      {landingVideoTitle && (
                <Container>
                  <Row className="justify-content-center">
                    <Col className="text-center">
                      <h2>{landingVideoTitle}</h2> {/* Ensure this line is correct */}
                    </Col>
                  </Row>
                </Container>
        )}

        <hr className="dashed-line" />

        <Row className="justify-content-center">
          <Col className="text-center">
            <Link to="/musics/" className="see-all-link">
              Latest Musics
            </Link>
          </Col>
        </Row>

        {/* Spotify Embed */}
        {landingSpotifyUrl && (
          <Container> {/* Wrap Spotify iframe in a Container */}
            <Row>
              <Col>
                <iframe
                  title="Spotify Embed"
                  style={{ borderRadius: '12px' }}
                  src={landingSpotifyUrl}
                  width="100%"
                  height="352"
                  frameBorder="0"
                  allowFullScreen
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy">
                </iframe>
              </Col>
            </Row>
          </Container>
        )}

        {distrokidUrl && (
          <Container>
            <Row className="justify-content-center">
              <Col className="text-center">
                <Link to={distrokidUrl} className="see-all-link">
                  See more platforms
                </Link>
              </Col>
            </Row>
          </Container>
        )}

        <hr className="dashed-line" />

        <Row className="justify-content-center">
          <Col className="text-center">
            <Link to="/events/" className="see-all-link">
              Upcoming Events
            </Link>
          </Col>
        </Row>

        {latestEvent && (
          <Container> {/* Wrap Event component in a Container */}
            <Row>
              <Col>
                <Event key={latestEvent.id} event={latestEvent} firebase={firebase} />
              </Col>
            </Row>
          </Container>
        )}

        {isAdmin && (
          <Container> {/* Wrap LandingUploadForm component in a Container */}
            <Row>
              <Col>
                <LandingUploadForm firebase={firebase} />
              </Col>
            </Row>
          </Container>
        )}
      </Container>
    </div>
  );
}

export default withFirebase(Landing);