import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './index.css';
import { withAuthentication } from '../Session';
import { withNavigation } from '../Navigation'; // Make sure to import withNavigation
import Landing from '../Landing';
import Contact from '../Contact';
import Products from '../Products';
import ProductDetail from '../ProductDetail';
import LoginPage from '../LoginPage';
import SignupPage from '../SignupPage';
import AccountPage from '../AccountPage';
import Musics from '../Musics';
import MusicDetail from '../MusicDetail';
import EventDetail from '../EventDetail';
import Events from '../Events';
import About from '../About';
import Studio from '../Studio';


const LandingWithNavigation = withNavigation(Landing);
const ContactWithNavigation = withNavigation(Contact);
const ProductsWithNavigation = withNavigation(Products);
const ProductDetailWithNavigation = withNavigation(ProductDetail);
const MusicsWithNavigation = withNavigation(Musics);
const MusicDetailWithNavigation = withNavigation(MusicDetail);
const EventsWithNavigation = withNavigation(Events);
const EventDetailWithNavigation = withNavigation(EventDetail);
const LoginPageWithNavigation = withNavigation(LoginPage);
const SignupPageWithNavigation = withNavigation(SignupPage);
const AccountPageWithNavigation = withNavigation(AccountPage);
const AboutWithNavigation = withNavigation(About);
const StudioWithNavigation = withNavigation(Studio);


function App () {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LandingWithNavigation />} />
                <Route path="products" element={<ProductsWithNavigation />} />
                <Route path="musics" element={<MusicsWithNavigation />} />
                <Route path="events" element={<EventsWithNavigation />} />
                <Route path="contact" element={<ContactWithNavigation />} />
                <Route path="about" element={<AboutWithNavigation />} />
                <Route path="studio" element={<StudioWithNavigation />} />
                <Route path="/product/:name" element={<ProductDetailWithNavigation />} />
                <Route path="/music/:name" element={<MusicDetailWithNavigation />} />
                <Route path="/event/:name" element={<EventDetailWithNavigation />} />
                <Route path="/login" element={<LoginPageWithNavigation />} />
                <Route path="/signup" element={<SignupPageWithNavigation />} />
                <Route path="/account" element={<AccountPageWithNavigation />} />
            </Routes>
        </BrowserRouter>
    );
}

export default withAuthentication(App);
