import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase'; // Make sure you have this HOC created as per your Firebase setup
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './index.css'; // Import the CSS module

const ContactBase = ({ firebase }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [requestPronouns, setRequestPronouns] = useState('');
  const [currentUser, setCurrentUser] = useState(null);

  // Check if the user is logged in and set email and name
  useEffect(() => {
    firebase.auth.onAuthStateChanged((authUser) => {
      setCurrentUser(authUser);
      if (authUser) {
        setEmail(authUser.email);
        setName(authUser.displayName || '');
      } else {
        setEmail(null);
        setName(null);
      }
    });
  }, [firebase.auth]);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submit behavior
  
    // Construct the email subject and body using form data
    const emailSubject = `Contact Form ${name} - ${subject}`;
    const emailBody = `
      Message from ${name} (${email})\n
      Pronouns: ${requestPronouns}\n
      Subject: ${subject}\n
      Message:\n${message}
      \n--------------------------------\n
      This message was sent from the contact form on the website.
    `;
  
    // Encode the components to make them mailto-safe
    const mailtoLink = `mailto:taime.martin@taime.co?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
  
    // Optionally, reset the form fields
    setEmail('');
    setName('');
    setRequestPronouns('');
    setSubject('');
    setMessage('');
  
    // Open the default mail client with the mailto link
    window.location.href = mailtoLink;
  
    // Optionally, provide feedback to the user
    alert('Please complete the email in your email client and send it.');
  };
  
  

  return (
    <div className="center-container">
    <Form onSubmit={handleSubmit} className="centered-form">
        <Form.Group controlId="formGroupName">
          <Form.Control
            type="text"
            placeholder="Your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            readOnly={!!currentUser}
          />
        </Form.Group>
        <Form.Group controlId="formGroupEmail">
          <Form.Control
            type="email"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            readOnly={!!currentUser}
          />
        </Form.Group>
        <Form.Group controlId="formGroupPronouns">
                  <Form.Control
                    type="text"
                    placeholder="Pronouns"
                    value={requestPronouns}
                    onChange={(e) => setRequestPronouns(e.target.value)}
                  />
                </Form.Group>
        <Form.Group controlId="formGroupSubject">
          <Form.Control
            as="select"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          >
            <option value="">Select...</option>
            <option value="Feedback">Feedback</option>
            <option value="Collaboration">Collaboration</option>
            <option value="Hiring">Hiring</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formGroupMessage">
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="submit-button">
          Send Message
        </Button>
        </Form>
    </div>
  );
};


const Contact = withFirebase(ContactBase);

export default Contact;
