import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Firebase from '../Firebase/firebase';
import YouTube from 'react-youtube';
import { Container, Row, Col } from 'react-bootstrap';

import './index.css';

const firebase = new Firebase();

function MusicDetail() {
  const { name } = useParams() || {};  // Safeguard against undefined values
  const [music, setMusic] = useState(null);

  useEffect(() => {
    // Ensure name exists before fetching the music
    if (name) {
      const fetchMusic = async () => {
        console.log(`Fetching music with name: ${name}`);
        try {
          const fetchedMusic = await firebase.getMusicByName(name);
          setMusic(fetchedMusic);
          console.log(`Fetched music: `, fetchedMusic);
        } catch (error) {
          console.error("Error fetching music: ", error);
        }
      };

      fetchMusic();
    }
  }, [name]);

  // Function to extract YouTube video ID from URL
  const getYouTubeID = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  const youtubeID = music && music.youtubeUrl ? getYouTubeID(music.youtubeUrl) : null;

  function formatDescription(description) {
    return description.split('\\n').map((text, index) => (
      <React.Fragment key={index}>
        {text}
        <br />
      </React.Fragment>
    ));
  }
  

  if (!music) {
    return <div>Loading...</div>;
  }

  return (
    <Container fluid className="music-detail">
    {/* For the music name */}
    <Row className="justify-content-center">
      <Col>
        <h2 className="music-name text-center">{music.name}</h2>
      </Col>
    </Row>
  
    {/* For the YouTube video or image */}
    <Row className="justify-content-center">
  {/* This 'md' value will allow images to take up 8 out of 12 possible columns on medium or larger screens */}
  <Col md={8} className="text-center"> 
    {youtubeID ? (
      <YouTube
      videoId={youtubeID}
      /* You can adjust the width and height here */
      opts={{
        width: '100%', // Set the width to take the full container width
        height: '500', // Set the desired height, adjust as needed
      }}
    />
    ) : (
      <div className="d-flex justify-content-center flex-wrap">
        {music.imageUrls && music.imageUrls.map((imageUrl, index) => (
          <img key={index} src={imageUrl} alt={`${music.name} ${index + 1}`} className="music-image img-fluid" />
        ))}
      </div>
    )}
  </Col>
</Row>

  
    {/* For the description */}
    <Row className="justify-content-center">
    <Col className="text-center mb-3 mt-3"> {/* mb-3 is a Bootstrap class for margin-bottom */}
      {formatDescription(music.description)}
    </Col>
    </Row>
  
    {/* For the Spotify iframe and platform link */}
    <Row className="justify-content-center">
    <Col lg={8} xl={6}> {/* Adjust these values to increase the width on larger screens */}
      <iframe 
        title="Spotify Embed"
        className="spotify-embed"
        src={music.spotifyUrl}
        frameBorder="0"
        allowFullScreen
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
        style={{ width: '100%', height: '352px', borderRadius: '12px' }}> {/* Remove inline styles if using CSS classes */}
      </iframe>
      
    </Col>
  </Row>

  <Row className="justify-content-center">
    <Col className="text-center">
      <div className="platforms-link-container">
        <a href={music.distrokidUrl} className="platforms-link">See more platforms</a>
      </div>
    </Col>
  </Row>

  </Container>
  
    );
  }
  
export default MusicDetail;