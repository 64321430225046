import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';
import StudioUploadForm from '../StudioUploadForm'; // Adjust the path as necessary
import { InstagramEmbed } from 'react-social-media-embed';
import { Container, Row, Col } from 'react-bootstrap';
import './index.css';

const Studio = ({ firebase }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [StudioContent, setStudioContent] = useState({
    title: '',
    description: '',
    instagramUrl: '',
    imageUrls: [], // Changed to an array to hold multiple images
  });

  useEffect(() => {
    // Subscription to the StudioPage content
    const unsubscribeStudioPage = firebase.db.collection('StudioPage').doc('content').onSnapshot((doc) => {
      if (doc.exists) {
        setStudioContent(doc.data());
      } else {
        console.log("No such document!");
      }
    });

    // Subscription to the auth state to get user details
    const unsubscribeAuth = firebase.auth.onAuthStateChanged(async user => {
      if (user) {
        const userRef = firebase.db.collection('users').doc(user.uid);
        const userDoc = await userRef.get();
        if (userDoc.exists) {
          setUserDetails(userDoc.data());
        }
      } else {
        setUserDetails(null);
      }
    });

    // Unsubscribe from both when the component unmounts
    return () => {
      unsubscribeStudioPage();
      unsubscribeAuth();
    };
  }, [firebase.db, firebase.auth]);

  const isAdmin = userDetails && userDetails.admin;

  function formatDescription(description) {
    return description.split('\\n').map((text, index) => (
      <React.Fragment key={index}>
        {text}
        <br />
      </React.Fragment>
    ));
  }

  // Split images into two groups for left and right columns
  const leftImages = StudioContent.imageUrls.filter((_, index) => index % 2 === 0);
  const rightImages = StudioContent.imageUrls.filter((_, index) => index % 2 !== 0);

  return (
    <Container className="studio-container">
      <Row>
        <Col sm={4}>
          {leftImages.map((url, index) => (
            <img key={`left-${index}`} src={url} alt={`Left ${index}`} className="img-fluid" />
          ))}
        </Col>
        <Col sm={4} className="text-center">
          <div className="text-container">
            <h1 className="studio-title">{StudioContent.title}</h1>
            {formatDescription(StudioContent.description)}
          </div>
        </Col>
        <Col sm={4}>
          {rightImages.map((url, index) => (
            <img key={`right-${index}`} src={url} alt={`Right ${index}`} className="img-fluid" />
          ))}
        </Col>
      </Row>

      {StudioContent.instagramUrl && (
        <Row className="justify-content-center my-4 instagram-row">
          <Col md={8} lg={6}>
            <h2 className="instagram-title">Instagram</h2>
            <InstagramEmbed url={StudioContent.instagramUrl} />
          </Col>
        </Row>
      )}
      
      {isAdmin && (
        <Row>
          <Col>
            <StudioUploadForm firebase={firebase} />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default withFirebase(Studio);