import React, { useState } from 'react';
import './index.css'; // Import styles specific to this component
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase'; // Import the Firebase higher-order component

const Music = ({ isAdmin, music, firebase }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [order, setOrder] = useState(music.order || 0); // Initialize with music's current order
  const [musics, setMusics] = useState([]); // You might want to pass this from a parent component

  // Function to handle order change
  const handleOrderChange = (e) => {
    setOrder(e.target.value);
  };

  // Function to save the new order to Firestore
  const saveOrder = () => {
    // Query for the music by name
    firebase.db.collection('musics').where('name', '==', music.name).get()
      .then(querySnapshot => {
        // Check if the music exists
        if (!querySnapshot.empty) {
          // Get the first document matching the music name
          const musicDoc = querySnapshot.docs[0];

          // Update the order field of the found document
          musicDoc.ref.update({ order: Number(order) })
            .then(() => {
              setIsEditing(false);
              alert('Order updated successfully!');
            })
            .catch(error => {
              alert('Error updating order: ' + error.message);
            });
        } else {
          alert('No music found with the given name.');
        }
      })
      .catch(error => {
        alert('Error finding music: ' + error.message);
      });
  };

  const handleDeleteMusic = async (name) => {
    if (window.confirm('Are you sure you want to delete this music?')) {
      try {
        const query = firebase.db.collection('musics').where("name", "==", name);
        const querySnapshot = await query.get();
        const batch = firebase.db.batch();

        querySnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        await batch.commit();
        setMusics(musics.filter(m => m.name !== name));
        alert('Music deleted successfully!');
      } catch (error) {
        alert('Error removing music: ' + error.message);
      }
    }
  };

  return (
    <div className="music">
      <Link to={`/music/${music.name}`}>
        <div className="music-container">
          {music.imageUrls && music.imageUrls[0] && (
            <div className="music-image-container">
              <img src={music.imageUrls[0]} alt="First cover" className="music-image" />
            </div>
          )}
          {music.imageUrls && music.imageUrls[1] && (
            <div className="music-image-container">
              <img src={music.imageUrls[1]} alt="Second cover" className="music-image" />
            </div>
          )}
          {music.imageUrls && music.imageUrls[2] && (
            <div className="music-image-container">
              <img src={music.imageUrls[2]} alt="Second cover" className="music-image" />
            </div>
          )}
          {/* Additional content */}
        </div>

      </Link>
      <Link to={`/music/${music.name}`} className="music-name">
        {music.name}
      </Link>
      {/* Show order input field only for admin users */}
      {isAdmin && (
        <div>
          {isEditing ? (
            <div>
              <input
                type="number"
                value={order}
                onChange={handleOrderChange}
              />
              <button onClick={saveOrder}>Save</button>
              <button onClick={() => setIsEditing(false)}>Cancel</button>
            </div>
          ) : (
            <button onClick={() => setIsEditing(true)}>Edit Order</button>
          )}
          {isAdmin && (
            <button onClick={() => handleDeleteMusic(music.name)}>
              Delete
            </button>
          )}
        </div>
      )}
      {/* You can add more music details or buttons/actions here */}
    </div>
  );
};

export default withFirebase(Music); // Wrap Music with withFirebase HOC
