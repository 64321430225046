import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Firebase from '../Firebase';
import YouTube from 'react-youtube';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';

import './index.css';

const firebase = new Firebase();

function ProductDetail() {
  const { name } = useParams() || {};  // Safeguard against undefined values
  const [product, setProduct] = useState(null);
  const [requestEmail, setRequestEmail] = useState(''); // For non-logged in users
  const [isRequesting, setIsRequesting] = useState(false); // To show/hide the request form
  const [requestMessage, setRequestMessage] = useState(''); // State for the user's message
  const [requestName, setRequestName] = useState('');
  const [requestPronouns, setRequestPronouns] = useState('');
  const [requestSize, setRequestSize] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserName, setCurrentUserName] = useState('');


  useEffect(() => {
    // Ensure name exists before fetching the product
    if (name) {
      const fetchProduct = async () => {
        console.log(`Fetching product with name: ${name}`);
        try {
          const fetchedProduct = await firebase.getProductByName(name);
          setProduct(fetchedProduct);
          console.log(`Fetched product: `, fetchedProduct);
        } catch (error) {
          console.error("Error fetching product: ", error);
        }
      };

      fetchProduct();
    }
  }, [name]);

  useEffect(() => {
    // Listener for Firebase authentication state
    const unsubscribe = firebase.auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      if (user) {
        // Prepopulate the email and name if the user is logged in
        setRequestEmail(user.email);
        setCurrentUserName(user.displayName || ''); // Use displayName or an empty string if not available
        setRequestName(user.displayName || ''); // Prepopulate the name in the request form
      } else {
        // Reset fields if the user logs out
        setCurrentUserName('');
        setRequestEmail('');
        setRequestName('');
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);



  // Function to handle the request submission
  const handleRequestSubmit = async () => {
    // Check if all fields are filled in
    if (!requestName || !requestPronouns || !requestSize || !requestEmail || !requestMessage) {
      alert('All fields are required.');
      return;
    }

    // Construct the request object with additional fields
    const productRequest = {
      email: requestEmail,
      name: requestName,
      pronouns: requestPronouns,
      size: requestSize,
      productName: product.name,
      message: requestMessage,
      requestDate: new Date()
    };

    try {
      // Save the request to the Firestore database
      await firebase.db.collection('requests').add(productRequest);

      // After saving to the database, construct the mailto link
      const emailSubject = `Product Request from ${requestName} for ${product.name}`;
      const emailRecipient = 'tim.martin@taime.co';
      const emailBody = `You received a new product request:
      
        Name: ${requestName}
        Pronouns: ${requestPronouns}
        Size: ${requestSize}
        Email: ${requestEmail}
        Message: ${requestMessage}
      `;

      // Encode the components to make them mailto-safe
      const mailtoLink = `mailto:${emailRecipient}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;

      // Reset the form fields
      setRequestName('');
      setRequestPronouns('');
      setRequestSize('');
      setRequestEmail('');
      setRequestMessage('');
      setIsRequesting(false); // Close the request form/modal

      // Open the default mail client
      window.location.href = mailtoLink;

      alert('Your request has been recorded.');
    } catch (error) {
      alert(error.message);
    }
  };


  const opts = {
    playerVars: {
      controls: 1, // Show controls
      modestbranding: 1, // Limit YouTube branding
      rel: 0, // Don't show related videos on pause or end
      showinfo: 0, // Hide video info
      autoplay: 1, // Disable autoplay to prevent the video from playing
    },
  };

  const songopts = {
    height: '0', // Minimal height to show controls
    width: '0', // Width to match your layout
    playerVars: {
      controls: 1, // Show controls
      modestbranding: 1, // Limit YouTube branding
      rel: 0, // Don't show related videos on pause or end
      showinfo: 0, // Hide video info
      autoplay: 1, // Disable autoplay to prevent the video from playing
    },
  };

  const _onReady = (event) => {
    // Access to player in all event handlers via event.target
    event.target.pauseVideo(); // Just to make sure it's not playing
    event.target.playVideo();
    setTimeout(() => event.target.pauseVideo(), 5000); // Play for 5 seconds
  }

  // Function to extract YouTube video ID from URL
  const getYouTubeID = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  const youtubeID = product && product.videoUrl ? getYouTubeID(product.videoUrl) : null;
  const songYoutubeID = product && product.songUrl ? getYouTubeID(product.songUrl) : null;

  return (
    <Container className="product-detail">
      <Row className="justify-content-center align-items-center top-section">
        <div className="name-container">
          <h1 className="product-name">{product.name}</h1>
        </div>

        {/* Make a Request Button */}
        <div className="request-button-container">
          <div className="request-button-overlay"></div>
          <Button className="request-button" onClick={() => setIsRequesting(true)}>
            Make a Request
          </Button>
        </div>

        <Row className="justify-content-center">
          <Col md={6}>
            {/* Request Modal */}
            <Modal show={isRequesting} onHide={() => setIsRequesting(false)} centered>
              <Modal.Header closeButton>
                <Modal.Title>Make a Request</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="formGroupName">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      value={requestName}
                      onChange={(e) => setRequestName(e.target.value)}
                      readOnly={!!currentUserName} // Make the name field read-only if the user is logged in
                    />
                  </Form.Group>
                  <Form.Group controlId="formGroupPronouns">
                    <Form.Control
                      type="text"
                      placeholder="Pronouns"
                      value={requestPronouns}
                      onChange={(e) => setRequestPronouns(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="formGroupSize">
                    <Form.Control
                      type="text"
                      placeholder="Size"
                      value={requestSize}
                      onChange={(e) => setRequestSize(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="formGroupEmail">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      value={requestEmail}
                      onChange={(e) => setRequestEmail(e.target.value)}
                      readOnly={currentUser != null} // Make the email field read-only if the user is logged in
                    />
                  </Form.Group>
                  <Form.Group controlId="formGroupMessage">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter your message"
                      value={requestMessage}
                      onChange={(e) => setRequestMessage(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setIsRequesting(false)}>
                  Close
                </Button>
                <Button className="modal-submit-button" onClick={handleRequestSubmit}>
                  Submit Request
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={10}>
            {/* Product images */}
            {product.imageUrls && (
              <Row className="justify-content-center">
                {product.imageUrls.map((imageUrl, index) => (
                  <Col xs={12} sm={8} md={10} lg={10} key={index}> {/* Adjust the 'xs', 'sm', 'md', and 'lg' values as needed */}
                    <img className="product-image" src={imageUrl} alt={`${product.name} ${index + 1}`} />
                  </Col>
                ))}
              </Row>
            )}
            <Row className="justify-content-center text-center product-description-row">
              <Col md={8}>
                <div className="product-description">{product.description}</div>
              </Col>
            </Row>
          </Col>
          <Col md={8}>
            <Row className="justify-content-center">
              {/* Main image or YouTube video */}
              {youtubeID ? (
                <div className="youtube-video"> {/* Add this wrapper if needed */}
                  <YouTube videoId={youtubeID} opts={{ ...opts, height: '100%', width: '100%' }} onReady={_onReady} />
                </div>
              ) : (
                <div></div>
              )}
            </Row>
          </Col>
        </Row>
      </Row>
      {/* The music from songUrl should start playing, but not display the video player */}
      {
        songYoutubeID && !youtubeID && (
          <YouTube videoId={songYoutubeID} opts={songopts} onReady={_onReady} className="d-none" />
        )
      }

    </Container>
  );
}

export default ProductDetail;
