// Events.js
import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase'; // Import the higher-order component
import Event from '../Event';
import EventUploadForm from '../EventUploadForm'; // Import the EventUploadForm component
import "./index.css"

const Events = ({ firebase }) => {
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);
    const [userDetails, setUserDetails] = useState(null);

    useEffect(() => {
        const fetchEvents = async () => {
          const now = new Date();
          const fetchedEvents = await firebase.db.collection('events')
            .orderBy('date', 'desc') // Assuming 'date' is the field with the event date
            .get()
            .then(querySnapshot => querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

          // Separate upcoming and past events
          const upcoming = fetchedEvents.filter(event => new Date(event.date) >= now);
          const past = fetchedEvents.filter(event => new Date(event.date) < now);

          setUpcomingEvents(upcoming);
          setPastEvents(past);
        };
    
        const unsubscribe = firebase.auth.onAuthStateChanged(async user => {
          if (user) {
            const userRef = firebase.db.collection('users').doc(user.uid);
            const userDoc = await userRef.get();
            if (userDoc.exists) {
              setUserDetails(userDoc.data());
            }
          } else {
            setUserDetails(null);
          }
        });
    
        fetchEvents();
        return () => unsubscribe(); // Cleanup subscription on unmount
    }, [firebase]);
    
    const isAdmin = userDetails && userDetails.admin;

    // ...rest of your component

    return (
      <div className="events-container">
          <h2 className="events-header">Upcoming Events</h2>
          {upcomingEvents.length > 0 ? (
            <ul className="events-list">
              {upcomingEvents.map(event => (
                  <li className="event-item" key={event.id}>
                      <Event event={event} isAdmin={isAdmin} />
                  </li>
              ))}
            </ul>
          ) : (
            <p>Coming Soon!</p>
          )}
  
          <h2 className="events-header">Past Events</h2>
          {pastEvents.length > 0 ? (
            <ul className="events-list">
              {pastEvents.map(event => (
                  <li className="event-item" key={event.id}>
                      <Event event={event} isAdmin={isAdmin} />
                  </li>
              ))}
            </ul>
          ) : (
            <p>No past events to show.</p>
          )}
  
          {isAdmin && <EventUploadForm firebase={firebase} />}
      </div>
  );
};

export default withFirebase(Events);
