import React from 'react';
import LogoInstagram from '../../images/instagram.png';
import LogoEmail from '../../images/email.png';
import LogoSpotify from '../../images/spotify.png';
import LogoYoutube from '../../images/youtube.png';

import './index.css';

const Credits = () => (
    <div className="credits-container">
        <div className="credits-middle">
            <div className="credits-title">
                TAIME.CO
            </div>
            <div className="credits-links">
                <a href="https://www.instagram.com/i_taime_martin">
                    <img src={LogoInstagram} alt="Instagram" />
                </a>
                <a href="mailto:taime.martin@taime.co">
                    <img src={LogoEmail} alt="Email" />
                </a>
                <a href="https://open.spotify.com/artist/0y8cbcpSC6TC9WwW9GoqaU">
                    <img src={LogoSpotify} alt="Spotify" />
                </a>
                <a href="https://www.youtube.com/channel/UC0-LWlPUsBPsKR_0WJ8ppBA">
                    <img src={LogoYoutube} alt="Youtube" />
                </a>
            </div>
            <div className="credits-author">
                Made by&nbsp;<a href='https://github.com/MarvinMartin24/'>Marvin Martin</a>
            </div>
        </div>
    </div>
);

export default Credits;
