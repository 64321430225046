import React, { useState, useMemo } from 'react';
import Firebase from '../Firebase'; // Replace '../Firebase' with the actual path to your Firebase class

function MusicUploadForm() {
  // Create an instance of Firebase
  const firebase = useMemo(() => new Firebase(), []);

  // State hooks for music details
  const [musicName, setMusicName] = useState('');
  const [musicDescription, setMusicDescription] = useState('');
  const [musicCategory, setMusicCategory] = useState('');
  const [musicSubCategory, setMusicSubCategory] = useState('');
  const [spotifyUrl, setSpotifyUrl] = useState('');
  const [distrokidUrl, setDistrokidUrl] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [musicFiles, setMusicFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [musicOrder, setMusicOrder] = useState(0); // State for music order

  // Function to handle the music image file selection
  const handleMusicImageChange = (e) => {
    setMusicFiles(Array.from(e.target.files));
  };

  // Function to handle the change of music order
  const handleMusicOrderChange = (e) => {
    setMusicOrder(Number(e.target.value));
  };

  const handleUploadMusic = () => {
    const promises = [];
    musicFiles.forEach((file) => {
      const uploadTask = firebase.storage.ref(`music_images/${file.name}`).put(file);
      promises.push(new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setUploadProgress(progress);
          },
          (error) => {
            reject(error);
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((url) => {
              resolve(url);
            });
          }
        );
      }));
    });

    Promise.all(promises).then((imageUrls) => {
      firebase.db.collection('musics').add({
        name: musicName,
        category: musicCategory,
        subCategory: musicSubCategory,
        description: musicDescription,
        spotifyUrl: spotifyUrl,
        distrokidUrl: distrokidUrl,
        youtubeUrl: youtubeUrl,
        order: musicOrder, // Add the order here
        imageUrls: imageUrls
      }).then(() => {
        setMusicName('');
        setMusicCategory('');
        setMusicSubCategory('');
        setMusicDescription('');
        setSpotifyUrl('');
        setDistrokidUrl('');
        setYoutubeUrl('');
        setMusicFiles([]);
        setMusicOrder(0); // Reset order to default
        setUploadProgress(0);
        alert('Music uploaded successfully!');
      }).catch((error) => {
        alert('Error uploading music: ' + error.message);
      });
    }).catch((error) => {
      alert('Error uploading images: ' + error.message);
    });
  };

  // Render the music upload form
  return (
    <div className="center-container">
      <div className="centered-form">

      <h2>Music Upload</h2>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div>
          <h3>Name</h3>
          <input
            className="form-control"
            type="text"
            placeholder="Enter music name..."
            onChange={(e) => setMusicName(e.target.value)}
            value={musicName}
          />
        </div>

        <div>
          <h3>Category</h3>
          <select
            className="form-control"
            value={musicCategory}
            onChange={(e) => setMusicCategory(e.target.value)}
          >
            <option value="">Select category...</option>
            <option value="Taime">Taime</option>
            <option value="Collaboration">Collaboration</option>
          </select>
        </div>

        <div>
          <h3>SubCategory</h3>
          <select
            className="form-control"
            value={musicSubCategory}
            onChange={(e) => setMusicSubCategory(e.target.value)}
          >
            <option value="">Select subcategory...</option>
            <option value="Album">Album</option>
            <option value="EP">EP</option>
            <option value="Single">Single</option>
          </select>
        </div>

        <div>
          <h3>Description</h3>
          <textarea
            className="form-control"
            placeholder="Enter music description..."
            onChange={(e) => setMusicDescription(e.target.value)}
            value={musicDescription}
          />
        </div>
        
        <div>
          <h3>Spotify URL</h3>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Spotify URL..."
            onChange={(e) => setSpotifyUrl(e.target.value)}
            value={spotifyUrl}
          />
        </div>

        <div>
          <h3>Distrokid URL</h3>
          <input
            className="form-control"
            type="text"
            placeholder="Enter Distrokid URL..."
            onChange={(e) => setDistrokidUrl(e.target.value)}
            value={distrokidUrl}
          />
        </div>

        <div>
          <h3>Youtube URL</h3>
          <input
            className="form-control"
            type="text"
            placeholder="Enter Youtube URL..."
            onChange={(e) => setYoutubeUrl(e.target.value)}
            value={youtubeUrl}
          />
        </div>
        
        <div>
          <h3>Images</h3>
          <input
            className="form-control"
            type="file"
            multiple
            onChange={handleMusicImageChange}
          />
        </div>

        <div>
          <h3>Order</h3>
          <input
            className="form-control"
            type="number"
            placeholder="Enter music order..."
            onChange={handleMusicOrderChange}
            value={musicOrder}
          />
        </div>
        
        <button className="submit-button" onClick={handleUploadMusic}>Upload Music</button>
      </div>
      {uploadProgress > 0 && (
        <progress value={uploadProgress} max="100" />
      )}
    </div>
    </div>
  );
}

export default MusicUploadForm;
