import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Firebase from '../Firebase/firebase';
import "./index.css"

const firebase = new Firebase();

function EventDetail() {
  const { name } = useParams() || {};  // Safeguard against undefined values
  const [event, setEvent] = useState(null);

  useEffect(() => {
    // Ensure name exists before fetching the event
    if (name) {
      const fetchEvent = async () => {
        console.log(`Fetching event with name: ${name}`);
        try {
          const fetchedEvent = await firebase.getEventByName(name);
          setEvent(fetchedEvent);
          console.log(`Fetched event: `, fetchedEvent);
        } catch (error) {
          console.error("Error fetching event: ", error);
        }
      };

      fetchEvent();
    }
  }, [name]);

  if (!event) {
    return <div>Loading...</div>;
  }

  function formatDescription(description) {
    return description.split('\\n').map((text, index) => (
      <React.Fragment key={index}>
        {text}
        <br />
      </React.Fragment>
    ));
  }

  return (
    <div className="event-detail-container">
        {event.imageUrls && event.imageUrls.map((imageUrl, index) => (
            <img key={index} src={imageUrl} alt={`${event.name} ${index + 1}`} className="event-detail-image" />
        ))}
        <h3 className="event-detail-heading">{event.name}</h3>
        <h3 className="event-detail-text">{event.date}</h3>
        <h3 className="event-detail-text">{formatDescription(event.description)}</h3>
        {event.partifulUrl && (
                <a href={event.partifulUrl} target="_blank" rel="noopener noreferrer" className="event-detail-link">
                    Participate
                </a>
            )}        {/* Add other event details here */}
    </div>
);
}

export default EventDetail;
