import React, { useState, useMemo } from 'react';
import Firebase from '../Firebase'; // Replace '../Firebase' with the actual path to your Firebase class

function EventUploadForm() {
  // Create an instance of Firebase
  const firebase = useMemo(() => new Firebase(), []);

  // State hooks for event details
  const [eventName, setEventName] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [partifulUrl, setPartifulUrl] = useState('');
  const [eventFiles, setEventFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  // Function to handle the event image file selection
  const handleEventImageChange = (e) => {
    setEventFiles(Array.from(e.target.files));
  };

  const handleUploadEvent = () => {
    const promises = [];
    eventFiles.forEach((file) => {
      const uploadTask = firebase.storage.ref(`event_images/${file.name}`).put(file);
      promises.push(new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setUploadProgress(progress);
          },
          (error) => {
            reject(error);
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((url) => {
              resolve(url);
            });
          }
        );
      }));
    });

    Promise.all(promises).then((imageUrls) => {
      firebase.db.collection('events').add({
        name: eventName,
        description: eventDescription,
        date: eventDate,
        partifulUrl: partifulUrl,
        imageUrls: imageUrls
      }).then(() => {
        setEventName('');
        setEventDescription('');
        setEventDate('');
        setPartifulUrl('');
        setEventFiles([]);
        setUploadProgress(0);
        alert('Event uploaded successfully!');
      }).catch((error) => {
        alert('Error uploading event: ' + error.message);
      });
    }).catch((error) => {
      alert('Error uploading images: ' + error.message);
    });
  };

  // Render the event upload form
  return (
    <div className="center-container">
      <div className="centered-form">
        <h2>Event Upload</h2>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
  
          <div>
            <h3>Name</h3>
            <input
              className="form-control"
              type="text"
              placeholder="Enter event name..."
              onChange={(e) => setEventName(e.target.value)}
              value={eventName}
            />
          </div>
  
          <div>
            <h3>Description</h3>
            <textarea
              className="form-control"
              placeholder="Enter event description..."
              onChange={(e) => setEventDescription(e.target.value)}
              value={eventDescription}
            />
          </div>
          
          <div>
            <h3>Date</h3>
            <input
              className="form-control"
              type="date"
              onChange={(e) => setEventDate(e.target.value)}
              value={eventDate}
            />
          </div>
  
          <div>
            <h3>Partiful URL</h3>
            <input
              className="form-control"
              type="text"
              placeholder="Enter Partiful URL..."
              onChange={(e) => setPartifulUrl(e.target.value)}
              value={partifulUrl}
            />
          </div>
  
          <div>
            <h3>Images</h3>
            <input
              className="form-control"
              type="file"
              multiple
              onChange={handleEventImageChange}
            />
          </div>
          
          <button className="submit-button" onClick={handleUploadEvent}>Upload Event</button>
          
          {uploadProgress > 0 && (
            <div style={{ width: '100%', marginTop: '10px' }}>
              <progress value={uploadProgress} max="100" style={{ width: '100%' }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
  
}

export default EventUploadForm;
